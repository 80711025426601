@import "../../styles/scss/index.scss";

.text-white-color {
    -webkit-text-fill-color: transparent;
    background-color: #fff;
    -webkit-background-clip: text;
    background-clip: text
}

.text-black-color {
    -webkit-text-fill-color: transparent;
    background-color: #000000;
    -webkit-background-clip: text;
    background-clip: text
}

.text-gradient-color {
    color: #fff;
    text-align: center;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(96.62deg, var(--gradient-color1, #e0257e) 25%, var(--gradient-color2, #8217ee));
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: 800;
}

.text-dark-colors {
    color: #2a2a2a !important;
}

.header_heading {
    color: #fff;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 42px;
    line-height: 1.25;
    padding-top: 6rem;
    padding-bottom: 2rem;

    @media (max-width: 768px) {
        padding-top: 2rem;
    }
}

.header_heading_2 {
  color: #f5f5f5;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 42px;
  line-height: 1.25;
  padding-top: 6rem;
  font-weight: bold;
  // padding-bottom: 2rem;

  @media (max-width: 768px) {
      padding-top: 2rem;
      font-size: 35px;
  }
}
.gradient_text {
    color: #fff;
    text-align: center;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(96.62deg, #e0257e 25%, #8217ee);
    -webkit-background-clip: text;
    background-clip: text;
}


.solid_color_text {
    color: #00f;
    text-align: center;
    -webkit-text-fill-color: #00f;
    -webkit-background-clip: text;
    background-clip: text;
}

.header_paragraph {
    color: #e4e4e4;
    text-align: center;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.25;
}

.section-header {
    &.is-home-header {
        // Default settings for smaller screens
        background-image: linear-gradient(transparent 10%, #000 22%), linear-gradient(270deg, #000 34%, transparent 80%), url('https://toppernotes.s3.ap-south-1.amazonaws.com/images/kJ2q5dgmpnX17varc399.png'), linear-gradient(#000, #000);
        background-position: 0 0, 0 0, 0 0, 0 0;
        background-repeat: repeat, repeat, no-repeat, repeat;
        background-size: auto, auto, contain, auto;

        // Media query for larger screens
        @media (min-width: 768px) { // Adjust this breakpoint as needed
            background-image: linear-gradient(transparent, #000 65%), linear-gradient(270deg, #000 66%, transparent), url('https://toppernotes.s3.ap-south-1.amazonaws.com/images/kJ2q5dgmpnX17varc399.png'), linear-gradient(#000, #000);
            // Other background properties remain the same and do not need to be repeated unless they change
        }
    }
}

// .testimonial-image {
//     max-width: 100%;
//     height: auto;
//     display: block;
//     margin: 0 auto;
//     border-radius: 20px;
// }

// .testimonial-grid-item {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100%;
//   }

  .testimonials-wrapper {
    display: none;
  }
  
  @media (min-width: 1200px) {
    .testimonials-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
  
    .swiper {
      display: none;
    }
  }
  
  .testimonial-item {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .testimonial-image {
    max-width: 100%;
    height: auto;
    border-radius: 20px;
  }
  
.button_freelancing {
    color: #fff;
    text-align: center;
    background-color: transparent;
    background-image: linear-gradient(96.62deg, #8217ee 26%, #e0257e);
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 2.25rem;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none; // Remove default anchor tag underline
    transition: transform 0.3s ease-in-out;

    &:hover {
        text-decoration: none; // Ensure no underline on hover
        transform: translate3d(0px, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
    }
}

.glare-btn {
    font-size: 16px;
    color: #fff;
    background-color: #463D7C;
    border-radius: 4px;
    height: 60px;
    // line-height: 60px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    .glare {
        position: absolute;
        width: 45px;
        height: 200%;
        transform: rotate(25deg);
        background-color: rgba(255, 255, 255, 0.3);
        top: -50%;
        left: -120%; // Start from the left
        animation: slide 2s ease infinite; // Continuous cycle
    }

    &:hover .glare {
        animation-play-state: paused; // Pause animation on hover
    }
}

@keyframes slide {
    from {
        left: -20%;
    }
    to {
        left: 120%;
    }
}

.glare:after {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 100%;
    background: inherit;
    right: -15px;
    top: 0;
}

.header_grid {
    grid-column-gap: 1.5rem;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1.1fr;
    grid-auto-columns: 1fr;
    margin-top: 4rem;
    display: grid;

    @media screen and (max-width: 767px) {
        margin-top: 0rem;
        grid-row-gap: 2rem;
        grid-template-columns: 1fr;
    }
}



.details_grid_item {
    background-color: #121212;
    border-radius: .875rem;
    flex-direction: row;
    //grid-template-rows: auto;
    //grid-template-columns: .5fr 2fr;
    //grid-auto-columns: 1fr;
    //grid-auto-flow: row;
    //align-content: center;
    align-items: center;
    justify-items: center;
    padding: .75rem 1rem;
    display: flex;
}

.details_grid_item_light {
    background-color: #bcfffc;
}

.details_hero_author_detail_light{
    color: #2a2a2a;
}

.details_component {

    background-color: #1a1a1a;
    border-radius: 1.125rem;
    position: relative;
    padding-top: 0.75rem;

    @media screen and (max-width: 767px) {
        display: block;
    }
}

.no_influencer_padding {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

.details_grid {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: stretch;
    margin-top: .75rem;
    margin-left: .75rem;
    margin-right: .75rem;
    margin-bottom: .75rem;
    display: grid;
}

.details_grid_heading {
    color: #fff;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.4;
    text-align: left;
    @media screen and (min-width: 991px) {
        font-size: 16px;
    }
}

.custom_img_size {
    width: 1.7rem;
    height: 1.7rem;
    margin-right: .75rem;
}

.details_wrapper {
    flex-direction: row;
    display: flex;
    align-items: center;  /* Align items vertically in the center */
    justify-content: space-between;  /* Distribute space between text and image */
    flex-wrap: wrap;  /* Allows items to wrap in smaller screens */

    @media screen and (max-width: 991px) {
        flex-direction: row;  /* Stack them vertically on smaller screens */
        align-items: center;  /* Center items horizontally when stacked */
    }
}

.details_content {
    flex: 1;  /* Allows the text block to fill the space available */
    padding: 1rem;  /* Add padding for spacing around text */
}

.details_image {
    max-width: auto;  /* Limit image width to maintain aspect ratio */
    max-height: 200px;  /* Maintain aspect ratio */
    @media screen and (max-width: 479px) {
        width: auto;  /* Full width on very small screens */
        max-height: 200px;  /* Maintain aspect ratio */
    }
}

.display_on_bigger_screens {
    @media (max-width: 767px) {
        display: none; /* Hides the top widget on small screens */
    }
}

.display_on_smaller_screens {
    display: none;
    @media (max-width: 767px) {
        display: block; /* Hides the top widget on small screens */
    }
}


// BannerSmall.module.scss
.banner_small {
    background-color: #ebf1ff;
    padding-top: 30px;
    padding-bottom: 70px;
}

.container_xl {

    width: 100%;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 60px;
    padding-right: 60px;

    @media screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/* SocialProof.module.css */
.BrandsImpactedLogoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    background: #ebf1ff;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .BrandsImpactedLogo {
    background-color: #161616;
    border: 1px solid rgba(255, 255, 255, 0.35);
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
    overflow: hidden;
  }

  .backgroundContainer {
    position: relative;
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    filter: blur(10px);
  }
  
  .overlayImageContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .centeredImage {
    width: auto;
    height: 100%;
  }

  .whiteText p {
    color: #dbdbdb !important;
  }

  .video-testimonial-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aspectRatioWrapper {
    position: relative;
    width: 100%;
    padding-bottom: 75%; /* 16:9 aspect ratio (100 / 16 * 9) */
    background-color: white;
    border-radius: 1rem;
    overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  }


  .videoTitle {
    position: absolute;
    bottom: 0px;
    margin: 1vw;
    // left: 50%;
    // transform: translateX(-90%);
    margin-top: 1rem;
    text-align: center;
    padding: 0.5vw;
    font-size: calc(0.5rem + 0.3vw);
    white-space: normal;
    word-wrap: break-word;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border-radius: 5px;

    @media (min-width: 480px) { 
        font-size: calc(0.6rem + 0.3vw);
    }

    @media (min-width: 768px) { 
        font-size: calc(0.75rem + 0.3vw);
    }
}
  
  .aspectRatioImage {
    position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
  }

  .aspectRatioImage:hover {
    transform: translate3d(0px, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }
  
  
  .Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    padding: 0;
    border: none;
    outline: none;
    width: 80%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 2;
  }
  
  .primaryButton {
    color: #fff;
    background-image: linear-gradient(101deg, #000000, #adadad);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 20px 40px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    text-decoration: none;
    display: flex;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
    cursor: pointer;
  }
  

  .testimonialWrapper {
    grid-row-gap: 8px;
    text-align: center;
    // background-image: linear-gradient(159deg, rgba(255, 255, 255, 0.1), rgba(217, 223, 255, 0.1));
    // border: 0.5px solid rgba(62, 62, 62, 0.51);
    border-radius: 16px;
    flex-direction: column;
    padding: calc(0.6rem + 0.3vw);
    display: flex;

    @media (min-width: 768px) { 
        padding: 20px;
    }
  }

  .testimonialWrapperLight {
    // background-image: linear-gradient(159deg, rgba(255, 255, 255, 0.1), rgba(217, 223, 255, 0.1));
    border: 0.5px solid #3e3e3e4d;
  }
  
  .testimonialWrapperLight:hover {
    // background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.1), var(--highlight-color)50);
    border: 2px solid var(--highlight-color);
  }

  .testimonialWrapperDark {
    // background-image: linear-gradient(159deg, rgb(19, 19, 19), rgba(217, 223, 255, 0.1));
    border: 0.5px solid #92929282;
  }

  .testimonialWrapperDark:hover {
    // background-color: #000;
    // background-image: linear-gradient(135deg, rgb(19, 19, 19), var(--highlight-color)50);
    border: 2px solid var(--highlight-color);
  }

  
  .lightboxLink {
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-bottom: 100%; /* 1:1 aspect ratio */
    height: 0; /* Maintain aspect ratio */
  }
  
  .imageFullSize {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }

  .imageFullSize:hover {
    transform: translate3d(0px, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }
  
  
  .playIcon {
    width: 10vw;
    height: 10vw;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (min-width: 768px) { 
        width: 5vw;
        height: 5vw;
        max-width: 80px;
        max-height: 80px;
    }
  }
  
  .textContainer {
    margin-top: 10px;
  }
  
  .nameText {
    font-size: calc(0.8rem + 0.3vw);
    line-height: 1.3;
    font-weight: bold;
    color: #fff;
    @media (min-width: 768px) { 
        font-size: 1.5rem;
    }
  }
  
  .postText {
    margin-top: 1px;
    font-size: 0.8rem;
    color: #adadad;
    @media (min-width: 768px) { 
        font-size: 1rem;
    }
  }

  .custom-row-testimonials {
    @media (min-width: 768px){
        row-gap: 1rem;
        column-gap: 0rem;
    }
  }

  .testimonialGrid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .testimonialCard {
    display: flex;
    flex-direction: column;
    width: 49%;
    margin-bottom: 16px;
    background-image: linear-gradient(135deg, #131313, #8217ee30);
    border: 1px solid #8217ee70;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }

  .testimonialCard:hover {
    transform: translate3d(0px, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    background-image: linear-gradient(135deg, #131313, #8217ee50);
    border: 1px solid #8217ee;
  }

  .testimonialGridRow {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 16px;
  }
  
  .testimonialContent {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
  }
  
  .testimonialImage {
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    overflow: hidden;
  }
  
  .imageFitCover {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .testimonialDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .testimonialNameWrapper {
    flex-grow: 1;
  }
  
  .testimonialName {
    font-weight: bold;
    font-size: 1.2em;
    color: #fff;
  }
  
  .testimonialPostWrapper {
    margin-top: 4px;
  }

  .testimonialFeedbackWrapper {
    margin-top: 20px;
    color: #ccc;
  }
  
  .testimonialPost {
    font-size: 1em;
    color: #aaa;
  }
  
  .companyLogoWrapper {
    display: flex;
    align-items: center;
  }
  
  @media screen and (max-width: 479px) {
    .testimonialGrid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px;
    }
  
    .testimonialCard {
      width: 100%;
    }
  }
  
  .loadMoreButton {
    color: #fff;
    text-align: center;
    background-color: #8217ee40;
    border: 1px solid #8217ee;
    border-radius: 8px;
    width: 8rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    padding: 16px;
    display: block;
    font-size: 16px;
    font-weight: 800;
  }

  .widgetButton {
    line-height: inherit;
    cursor: pointer;
}

/* ToolsYouWillLearn.module.css */
.job-guarantee-component {
    display: flex;
    flex-direction: column;
    row-gap: 132px; /* Adjusted from grid-row-gap for modern CSS */
}

.learning-tools-block {
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 24px;
    justify-content: center; */

    grid-column-gap: 40px;
    grid-row-gap: 40px;
    grid-template-rows: auto auto;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-auto-columns: 1fr;
    max-width: 580px;
    margin: 60px auto;
    display: grid;
    text-align: center;

    @media screen and (max-width: 680px) {
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        grid-template-rows: auto auto;
        grid-template-columns: auto auto;
        grid-auto-columns: 1fr;
        justify-content: center;
        display: grid;
    }
}

.tools-img {
    background-color: transparent;
    background-image: linear-gradient(to bottom right, #8236ee90, #000000);
    border: 1px solid #8236ee;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    margin-bottom: 12px;
    padding: 20px;

    @media screen and (max-width: 479px) {
        width: 25vw;
        height: 25vw;
    }
}

.tools-name {
    color: rgba(0, 0, 0, 0.9);
    font-size: 0.875rem;
    font-weight: 600;
}

/*================================================
Event Timeline Area CSS
=================================================*/
.customCardBodyLight {
    color: #222;
}

.customCardStyleLight {
    background-image: linear-gradient(to bottom right, #fff, #8236ee50) !important;
}

.customCardBodyLight h1,
.customCardBodyLight h2,
.customCardBodyLight h3,
.customCardBodyLight h4,
.customCardBodyLight h5,
.customCardBodyLight h6 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.customCardBodyLight p,
.customCardBodyLight a,
.customCardBodyLight span,
.customCardBodyLight li,
.customCardBodyLight ul,
.customCardBodyLight ol,
.customCardBodyLight div {
    color: #222;
}

.customCardBody {
    color: #c9c9c9;
}

.customCardBody h1,
.customCardBody h2,
.customCardBody h3,
.customCardBody h4,
.customCardBody h5,
.customCardBody h6 {
    color: #ffffff;
}

.customCardBody p,
.customCardBody a,
.customCardBody span,
.customCardBody li,
.customCardBody ul,
.customCardBody ol,
.customCardBody div {
    color: #c9c9c9;
}

.card.timeline_card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 1rem;
}

.card-img-container {
    background-color: #000000;
    border-radius: 1rem;
    height: 250px;
    overflow: hidden; /* Ensures the image doesn't overflow the container */
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-img, .card-img-top {
    border-top-left-radius: calc(1rem - 1px);
    border-top-right-radius: calc(1rem - 1px);
    background-size: cover; /* Ensures the background covers the entire area */
    background-color: var(--background-color);
    object-fit: contain; /* Ensures the image fits by height */
    height: 100%;
}

@media (min-width: 768px) {
    .card-img, .card-img-top {
        height: 250px; /* Sets the height to 50% of the available width */
    }
}

/* The actual timeline (the vertical ruler) */
.main-timeline-2 {
    position: relative;
  }
  
  /* The actual timeline (the vertical ruler) */
  .main-timeline-2::after {
    content: "";
    position: absolute;
    width: 3px;
    background-color: #8217ee;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
  
  /* Container around content */
  .timeline-2 {
    position: relative;
    background-color: inherit;
    width: 50%;
  }
  
  /* The circles on the timeline */
  .timeline-2::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    right: -11px;
    background-color: #8217ee;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
  
  /* Place the container to the left */
  .left-2 {
    padding: 0px 40px 20px 0px;
    left: 0;
  }
  
  /* Place the container to the right */
  .right-2 {
    padding: 0px 0px 20px 40px;
    left: 50%;
  }
  
  /* Add arrows to the left container (pointing right) */
  .left-2::before {
    content: " ";
    position: absolute;
    top: 18px;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
  }
  
  /* Add arrows to the right container (pointing left) */
  .right-2::before {
    content: " ";
    position: absolute;
    top: 18px;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }
  
  /* Fix the circle for containers on the right side */
  .right-2::after {
    left: -14px;
  }
  
  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media screen and (max-width: 991px) {
    /* Place the timelime to the left */
    .main-timeline-2::after {
      left: 31px;
    }
  
    /* Full-width containers */
    .timeline-2 {
      width: 100%;
      padding-left: 70px;
      padding-right: 25px;
    }
  
    /* Make sure that all arrows are pointing leftwards */
    .timeline-2::before {
      left: 60px;
      border: medium solid white;
      border-width: 10px 10px 10px 0;
      border-color: transparent white transparent transparent;
    }
  
    /* Make sure all circles are at the same spot */
    .left-2::after,
    .right-2::after {
      left: 18px;
    }
  
    .left-2::before {
      right: auto;
    }
  
    /* Make all right containers behave like the left ones */
    .right-2 {
      left: 0%;
    }
  }